



























































import { Component, Mixins } from 'vue-property-decorator';
import { ReportChartRingComponent, ReportChartHistogramComponent } from '@/mixins/report-chart-base';
import AccessControlIndexService from './service/access-control-index';
import { AccessControlAlarmTrendQueryModel } from './model/access-control-alarm-trend';
import { AccessControlRtsTypeQueryModel } from './model/access-control-rts-type';

@Component
export default class AccessControlIndexComponent extends Mixins(ReportChartRingComponent, ReportChartHistogramComponent) {
    headerCardList: Array<any> = [
        { title: '园内人数', count: null, unit: '人', loading: true, icon: '/img/device.png' },
        { title: '设备总数', count: null, unit: '台', loading: true, icon: '/img/device-online.png' },
        { title: '异常设备', count: null, unit: '台', loading: true, icon: '/img/fault.png' },
        { title: '今天告警', count: null, unit: '条', loading: true, icon: '/img/alarm-unhandle.png' },
        { title: '正在告警', count: null, unit: '条', loading: true, icon: '/img/alarm.png' }
    ];
    rtsTotalData: any = {};
    rtsTotalDataLoading: boolean = true;

    rtsTypeData: any = null;
    rtsTypeDataLoading: boolean = true;

    alarmStatusCountData: any = null;
    alarmStatusCountDataLoading: boolean = true;

    last7DayAlarmData: any = {};
    last7DayAlarmDataLoading: boolean = true;

    created() {
        this.initData();
    }

    initData() {
        AccessControlIndexService.getRtsCount().then(res => {
            this.headerCardList[0].count = res;
        }).finally(() => this.headerCardList[0].loading = false);

        AccessControlIndexService.getFacilityCount().then(res => {
            this.headerCardList[1].count = res.totalCount;
            this.headerCardList[2].count = res.faultCount;
        }).finally(() => {
            this.headerCardList[1].loading = false;
            this.headerCardList[2].loading = false;
        });

        AccessControlIndexService.getAlarmCount().then(res => {
            this.headerCardList[3].count = res.totalCount;
            // this.headerCardList[4].count = res.totalCount;
        }).finally(() => {
            this.headerCardList[3].loading = false;
            // this.headerCardList[4].loading = false;
        });

        AccessControlIndexService.getRtsTotalCount().then(res => {
            this.rtsTotalData = res;
        }).finally(() => this.rtsTotalDataLoading = false);

        const queryModel = new AccessControlRtsTypeQueryModel();
        AccessControlIndexService.getRtsTypeCount(queryModel).then(res => {
            this.rtsTypeData = res;
        }).finally(() => this.rtsTypeDataLoading = false);

        AccessControlIndexService.getAlarmStatusCount().then(res => {
            this.alarmStatusCountData = res;
            this.headerCardList[4].count = _.get(res, 'rows[1].value');
        }).finally(() => {
            this.alarmStatusCountDataLoading = false;
            this.headerCardList[4].loading = false;
        });

        const model = new AccessControlAlarmTrendQueryModel();
        AccessControlIndexService.getLast7DayAlarmCount(model).then(res => {
            this.last7DayAlarmData = res;
        }).finally(() => this.last7DayAlarmDataLoading = false);
    }
}
